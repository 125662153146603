import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { RouterConfig } from './routes';
import { AuthProvider } from './context/Auth';
import { ChatProvider } from './context/Chat';
import './styles/App.scss';

const theme = createTheme({
    typography: {
        fontFamily: 'Quicksand',
    }
});

const App = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <ChatProvider>
                    <MuiThemeProvider theme={theme}>
                        <RouterConfig />
                    </MuiThemeProvider>
                </ChatProvider>
            </AuthProvider>
        </BrowserRouter>
    );
};

export default App;
