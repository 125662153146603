import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Chat from 'components/chat/Index';
import useAuth from 'hooks/useAuth';
import useChat from 'hooks/useChat';
import UserNav from 'components/UserNav';

const useStyles = makeStyles(() => ({
    sidebarOpen : {
        paddingLeft :250,
        paddingTop : 64
    },
    sidebarClose :{
        paddingLeft : 0,
        paddingTop : 64
    }
}))

const UserLayout = (props) => {
    const classes = useStyles();
    const { currentUser } = useAuth();
    const { showChat } = useChat();
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);

    if (!currentUser) {
        console.log('dsfsaf');
        return <Redirect to="/login" />;
    }

    return (
        <React.Fragment>
            <UserNav  isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}/>
            <div className={isDrawerOpen ? classes.sidebarOpen : classes.sidebarClose}>
                {props.children}
            </div>
            {showChat && <Chat />}
        </React.Fragment>
    );
};

UserLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

export default UserLayout;
