import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import firebase from '@firebase/app';

import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { app, db } from 'firebaseConf';
import useChat from 'hooks/useChat';
import useAuth from 'hooks/useAuth';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    paper: {
        boxShadow: 'none',
        height : 'calc(100vh - 64px)',
        paddingTop : 20
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    chatContainer: {
        flexGrow: 1,
    },
    roomList: {
        cursor: 'pointer',
    },
    messageContainer: {
        maxWidth: '1024px',
        height : 'calc(100% - 100px)',
        overflow : 'hidden',
    
        background: '#FFFFFF',
        boxShadow: '0px 2px 15px rgb(0, 0, 0, 0.05)',
        margin: 'auto',
        marginBottom: '30px',
    },
    messageScrollArea:{
        height : '100%',
        padding: '25px 20px 15px',
        overflowX : 'hidden'
    },
    singleMsgInner: {
        marginTop: '8px',
        width: '100%',
        display: 'inline-block',
    },
    msgTxt: {
        whiteSpace: 'pre-line',
    },
    msgTime: {},
    msgWriteContainer: {
        height : 50,
        maxWidth: '1024px',
        margin: 'auto',
        border: 'none',
    },
    singleMsgInnerWrapper: {},
    loadMore: {
        textAlign: 'center',
        cursor: 'pointer',
    },
}));

const UserMessage = () => {
    const dummy = React.useRef();
    const {
        showChat,
        selectedRoom,
        setAllRooms,
        setSelectedRoom,
        setSelectedRoomAllMessages,
        messages,
    } = useChat();
    const { currentUser } = useAuth();
    const classes = useStyles();
    const [formValue, setFormValue] = useState('');

    const [startPosition, setStartPosition] = useState();

    const sendMessage = async (e) => {
        e.preventDefault();

        await db.collection('messages').add({
            text: formValue,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            receiver_view: false,
            room: selectedRoom,
            photoURL: '',
            sender_id: currentUser.uid,
        });

        setFormValue('');
        dummy.current.scrollIntoView({ behavior: 'smooth' });
    };

    const fetchMoreData = () => {
        console.log('fetch more');
        if (startPosition) {
            db.collection('messages')
                .where('room', '==', currentUser.uid)
                .orderBy('createdAt', 'desc')
                .startAfter(startPosition)
                .limit(40)
                .get()
                .then(function (documentSnapshots) {
                    var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                    const list = [];
                    documentSnapshots.forEach(function (doc) {
                        const {
                            createdAt,
                            photoURL,
                            room,
                            text,
                            sender_id,
                            receiver_view,
                        } = doc.data();

                        list.push({
                            key: doc.id,
                            createdAt: createdAt,
                            photoURL,
                            room,
                            text,
                            sender_id,
                            receiver_view,
                        });
                    });
                    const value = orderBy(list, 'createdAt', 'asc');
                    setStartPosition(lastVisible);
                    let mMsg = value.concat(messages);
                    setSelectedRoomAllMessages(mMsg);
                });
        }
    };

    const getUsers = async () => {
        app
            .firestore()
            .collection('user_details')
            .where('type', '==', 'admin')
            .get()
            .then((snapshot) => {
                const userDetails = snapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        name: doc.data().name,
                        email: doc.data().email,
                        loanId: doc.data().loanId,
                        phoneNumber: doc.data().phoneNumber,
                        type: doc.data().type,
                        uid: doc.data().uid,
                    };
                });
                setAllRooms(userDetails);
            });
    };

    useEffect(() => {
        if (showChat) {
            getUsers();
        }
    }, [showChat]);

    useEffect(() => {
        setSelectedRoom(currentUser.uid);
    }, []);

    useEffect(() => {
        const unsubscribe = db
            .collection('messages')
            .where('room', '==', currentUser.uid)
            .orderBy('createdAt', 'desc')
            .limit(40)
            .onSnapshot((querySnapshot) => {
                var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

                const data = querySnapshot.docs.map((doc) => {
                    if (
                        currentUser.uid !== doc.data().sender_id &&
            doc.data().receiver_view === false
                    ) {
                        db.collection('messages')
                            .doc(doc.id)
                            .update({
                                receiver_view: true,
                            })
                            .then(() => {
                                //console.log("Document updated"); // Document updated
                            })
                            .catch((error) => {
                                console.error('Error updating doc', error);
                            });
                    }
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                });
                setStartPosition(lastVisible);
                const value = orderBy(data, 'createdAt', 'asc');
                setSelectedRoomAllMessages(value);
                dummy.current.scrollIntoView({ behavior: 'smooth' });
            });
        return unsubscribe;
    }, []);

    return (
        <Paper
            className={classes.paper}
            style={{
                background: '#F5FAFE',
            }}>
            <div className={classes.messageContainer}>
                <div className={classes.messageScrollArea}>
                    {startPosition && messages.length >= 40 && (
                        <div className={classes.loadMore}>
                            <span onClick={fetchMoreData}>Load more</span>
                        </div>
                    )}
                    {messages && messages.length > 0 &&
                        messages.map((msg, ind) => (
                            <div key={ind} className={classes.singleMsgInnerWrapper}>
                                <div className={classes.singleMsgInner}>
                                    <p className={msg.sender_id === currentUser.uid ? 'sender' : 'receiver'}>
                                        <span>{msg.text}</span>
                                    </p>

                                    <span className={classes.msgTime}>
                                        {/* {msg.createdAt.toDate()} */}
                                    </span>
                                </div>
                            </div>
                        ))
                    }
                    <span ref={dummy}></span>
                </div>
            </div>
            <div className={classes.msgWriteContainer}>
                <textarea
                    value={formValue}
                    rows="1"
                    onChange={(e) => setFormValue(e.target.value)}
                    placeholder="Write here"
                    className="msg_field single_msg_field"
                />
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!formValue}
                    onClick={sendMessage}
                    className="msg_send_btn"
                >
                    Send
                </Button>
            </div>
        </Paper>
    );
};

export default UserMessage;
