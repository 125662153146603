import { app } from 'firebaseConf';
import moment from 'moment';
import JSEncrypt from 'jsencrypt';

const storageRef = app.storage().ref();

const encrypter = new JSEncrypt();

encrypter.setPublicKey(atob(process.env.REACT_APP_ENCRYPTION_KEY));

export const encrypt = (string, prefix = 'encrypted:') => `${prefix}${encrypter.encrypt(string)}`;
export const setDecryptionKey = (key) => encrypter.setPrivateKey(key);
export const decrypt = (string = '', key) => {
    let value = string;
    if (string.indexOf('encrypted:') === 0) {
        value = string.split('encrypted:')[1];
    }
    if (key) {
        encrypter.setPrivateKey(key);
    }
    return encrypter.decrypt(value);
}

/**
 * 
 * @param {*} id 
 * @param {*} directory 
 * @param {*} file 
 * @returns {Promise<{directory:string,fileName:string}>}
 */
export const uploadDocument = (id, directory, file) => {
    const timeStamp = moment().unix();

    return new Promise((resolve => {
        const fileName = `${timeStamp}-${file.name}`
        const uploadTask = storageRef
            .child(`${directory}/${id}/${fileName}`)
            .put(file);

        uploadTask.on('state_changed', (snapshot) => {
            const percent = parseInt(snapshot.bytesTransferred / snapshot.totalBytes * 100);
            console.log(`uploading ${directory}:`, percent);
        }, null, () => {
            resolve({ directory, fileName });
            /*
            storageRef.storage
                .ref(directory)
                .child(`${id}/${fileName}`)
                .getDownloadURL()
                .then((url) => resolve({ directory, fileName, url }))
                .catch(() => resolve({ directory, fileName, url: null }))
            */
        });
    }));
};

export const getFileURL = async (id, directory, fileName) => {
    let url = null;

    if (fileName) {
        try {
            url = await storageRef.storage
                .ref(directory)
                .child(`${id}/${fileName}`)
                .getDownloadURL();
        } catch (err) {
            console.log('cant retrieve file', id, directory, fileName);
        }
    }

    return url;
};

export const _$ = (amount, showCurrency = true) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return showCurrency ? formatter.format(amount) : formatter.format(amount).replace('$', '');
};

export const getPP = (amount, rate, periodsCount, futureAmount = 0, type = 0) => {
    // type = 0 - payment is due at the end of the period; 1 - beginning of the period;
    let payment;
    let pow;

    if (rate === 0) {
        return -(amount + futureAmount) / periodsCount;
    }

    pow = Math.pow(1 + rate, periodsCount);
    payment = rate * amount * (pow + futureAmount) / (pow - 1);

    if (type === 1) {
        payment /= (1 + rate);
    }

    return payment;
};

export const parseNumeric = (value) => {
    const parsed = value.replace(/[^0-9.]/g, '');
    const dotIdx = parsed.indexOf('.');
    const noDots = parsed.replace(/\./g, '');

    if (dotIdx !== -1) {
        return `${noDots.substring(0, dotIdx)}.${noDots.substr(dotIdx, 2)}`;
    } else {
        return parsed;
    }
};

class Validator {
    isValid = false;
    error = '';
    value = null;

    check(value) {
        this.isValid = true;
        this.error = '';
        this.value = value;

        return this;
    }

    isRequired() {
        if (!this.value) {
            this.isValid = false;
            this.error = 'Field is Required';
        }

        return this;
    }

    isTrue() {
        if (this.value !== true) {
            this.isValid = false;
            this.error = 'Must be Checked';
        }

        return this;
    }

    isEmail(required) {
        const re = /\S+@\S+\.\S+/;

        if (!re.test(this.value)) {
            if (required || this.value?.length > 0) {
                this.isValid = false;
                this.error = 'Invalid Email';
            }
        }

        return this;
    }

    isPhone(required) {
        const m = (this.value || '').match(/\d/g);
        if (!m || m.length < 10 || m.length > 11) {
            if (required || this.value?.length > 0) {
                this.isValid = false;
                this.error = 'Invalid Phone';
            }
        }

        return this;
    }
}

export const validator = new Validator();

export const formatInitials = name => {
    let initials = name.split(' ');
    if (initials.length > 1) {
        if (initials.length > 2) {
            initials =
                initials.shift().charAt(0) +
                '. ' +
                initials.shift().charAt(0) +
                '. ' +
                initials.pop().charAt(0);
        } else {
            initials = initials.shift().charAt(0) + '. ' + initials.pop().charAt(0);
        }
    } else {
        initials = name.substring(0, 2);
    }
    return initials.toUpperCase();
}

