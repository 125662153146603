import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

const CoBorrowerGuard = (props) => {
    const { currentUser } = useAuth();

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    if (currentUser.userExtraInfo.type === 'user') {
        return <Redirect to="/user/dashboard" />;
    }

    if (currentUser.userExtraInfo.type === 'admin') {
        return <Redirect to="/admin/dashboard" />;
    }

    return <React.Fragment>{props.children}</React.Fragment>;
};

CoBorrowerGuard.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

export default CoBorrowerGuard;
