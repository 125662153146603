import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Chat from 'components/chat/Index';
import CoBorrowerNav from 'components/CoBorrowerNav';
import useAuth from 'hooks/useAuth';
import useChat from 'hooks/useChat';

const CoBorrowerLayout = (props) => {
    const { currentUser } = useAuth();
    const { showChat } = useChat();
    // console.log(currentUser);
    if (!currentUser) {
        console.log('dsfsaf');
        return <Redirect to="/login" />;
    }

    return (
        <React.Fragment>
            <CoBorrowerNav />
            {props.children}
            {showChat && <Chat />}
        </React.Fragment>
    );
};

CoBorrowerLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

export default CoBorrowerLayout;
