import React, { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Loading from '../components/Loading';
import { auth, app } from '../firebaseConf';

const initialAuthState = {
    currentUser: null,
};

const AuthContext = createContext({
    ...initialAuthState,
});

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = React.useState(null);
    const [currentUserId, setCurrentUserId] = React.useState('');
    const [loading, setLoading] = React.useState(true);
  
    function createUser(email, password, sendEmail = false) {
        return auth
            .createUserWithEmailAndPassword(email, password)
            .then((user) => {
                if (sendEmail) {
                    user.sendEmailVerification()
                        .then(() => console.log('verification email sent'))
                        .catch(() => console.log('verification email failed'))
                }
                return user;
            });
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    function logout() {
        sessionStorage.removeItem('MENU_SELECTED');
        return auth.signOut();
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email);
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email);
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                axios.interceptors.request.use(async config => {
                    config.headers.Authorization = `Bearer ${await auth.currentUser.getIdToken()}`;
                    return config;
                }, (error) => {
                    console.log('token error', error);
                });
                app
                    .firestore()
                    .collection('user_details')

                    .where('uid', '==', user.uid)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            setCurrentUser({ ...user, userExtraInfo: doc.data() });
                            // console.log('doc', doc.id);
                            setCurrentUserId(doc.id);
                        });
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log('error updating user', error);
                        setCurrentUser(null);
                        setLoading(false);
                    });
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        });

        return unsubscribe;
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                currentUserId,
                login,
                createUser,
                logout,
                resetPassword,
                updateEmail,
                updatePassword,
            }}>
            { children }
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

export default AuthContext;
