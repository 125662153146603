import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },

    drawer: {
        paddingTop: '5rem',
        width: '12rem',
    },
    drawerContainer: {
        overflow: 'auto',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemsList: {
        textAlign: 'left',
        display: 'block',
    },
    button: {
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    btnRoot: {
        paddingLeft: '25px',
        justifyContent: 'left !important',
    },
    subMenu: {
        paddingLeft: '50px !important',
    },
}));

export default function Navbar({ noLogin }) {
    const history = useHistory();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="fixed">
                <Toolbar>
                    <Typography variant="h1" className={classes.title} onClick={() => history.push('/')}>
                        Car Cash
                    </Typography>
                    {
                        !noLogin &&
                        <Button color="inherit" onClick={() => history.push('/login')}>
                            Login
                        </Button>
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}

Navbar.propTypes = {
    noLogin: PropTypes.bool,
};
