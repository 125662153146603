import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useAuth from '../hooks/useAuth';
import useChat from '../hooks/useChat';
import { List, ListItem, Drawer, Container, MenuItem, Avatar, Menu, Dialog, TextField } from '@material-ui/core';
import SmsIcon from '@material-ui/icons/Sms';
import Badge from '@material-ui/core/Badge';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import AirplayOutlinedIcon from '@material-ui/icons/AirplayOutlined';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useHistory } from 'react-router-dom';
import { app, auth } from '../firebaseConf';
import Swal from 'sweetalert2';
import { encrypt } from 'utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    footer: {
        position: 'fixed !important',
        bottom: 0,
        backgroundColor: 'transparent',
        width: '118px',
        right: '0px',
        border: 'none',
        boxShadow: 'none',
    // color: theme.co
    },
    drawer: {
        paddingTop: 70,
        width: 250,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemsList: {
        textAlign: 'left',
        display: 'block',
    },
    itemListShow: {
        background: '#3f51b5',
        color: '#FFFFFF',
    },
    button: {
    //color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    btnRoot: {
        paddingLeft: '25px',
        justifyContent: 'left !important',
    },
    subMenu: {
        paddingLeft: '50px !important',
    },
    activeMenu: {
        padding: '0 8px',
        '& button': {
            textTransform: 'capitalize',
            justifyContent: 'flex-start',
            minHeight: 48,
            backgroundColor: '#3f51b5',
            color: '#ffffff',
            padding: '10px 12px',
            '&:hover': {
                backgroundColor: '#3f51b5d9',
                color: '#ffffff',
            }
        }
    },
    inactiveMenu: {
        padding: '0 8px',
        '& button': {
            textTransform: 'capitalize',
            justifyContent: 'flex-start',
            minHeight: 48,
            padding: '10px 12px',

        }
    },
    largeAvatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        margin: 'auto'
    },
    userDropdown: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(3)
    }
}));

const useStylesForm = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '300px',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        },
    },

}));

export default function UserNav(props) {
    const classes = useStyles();
    const classes1 = useStylesForm();
    const history = useHistory();
    const { currentUser, currentUserId } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    // const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [selectedMenu, setSelectedMenu] = useState(
        sessionStorage.getItem('MENU_SELECTED')
            ? sessionStorage.getItem('MENU_SELECTED')
            : 'dashboard'
    );
    const { logout } = useAuth();
    const { setShowChat, showChat, allRoomUnSeenMessageCount } = useChat();

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: '',
        confirmPasswordErr: false,
        confirmPasswordErrMsg: 'New Password and Password Confirmation should match',
    });

    //Change Password Modal
    const handleOpen = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    // console.log(currentUser.userExtraInfo.password);
    // console.log(currentUserId);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.newPassword != formData.confirmPassword) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    confirmPasswordErr: true,
                };
            });
            return;
        }

        try {
            const user = auth.currentUser;
            user.updatePassword(formData.newPassword).then((success) => {
                console.log('success', success);
                Swal.fire('success', 'Password Changed Successfully', 'success');
                setOpen(false);
            }).catch(function (error) {
                Swal.fire('', error.message, 'info');
                setOpen(false);
            });
            // await logout();
        } catch (error) {
            setOpen(true);
            Swal.fire('', error.message, 'info');
        }
    };

    // const clickMenu = async(e,menu) => {
    //   {console.log(menu,'menu')}
    //   setselectedMenu(menu);
    //   if(menu === 'Dashboard')
    //   {
    //    history.push("/user/dashboard");
    //   }
    //   else if(menu === 'Applications')
    //   {
    //     history.push("/user/application");
    //   }
    //   else if(menu === 'My Contracts')
    //   {
    //     history.push("/user/contract");
    //   }
    //   else if(menu === 'Report an Accident')
    //   {
    //     history.push("/user/reportAccident");
    //   }
    //   else if(menu === 'Card')
    //   {
    //     history.push("/user/AddCard");
    //   }

    // };

    const handleChatOpen = useCallback(() => {
        setShowChat(true);
    }, [showChat]);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        console.log('log time');
        if (sessionStorage.getItem('MENU_SELECTED')) {
            setSelectedMenu(sessionStorage.getItem('MENU_SELECTED'));
        }
    }, [selectedMenu]);

    React.useEffect(() => {
        if (window.screen.width < 500) {
            props.setIsDrawerOpen(false);
        }
    }, []);

    const getInitials = (name) => {
        let initials = name.split(' ');
        if (initials.length > 1) {
            if (initials.length > 2) {
                initials =
          initials.shift().charAt(0) +
          '. ' +
          initials.shift().charAt(0) +
          '. ' +
          initials.pop().charAt(0);
            } else {
                initials = initials.shift().charAt(0) + '. ' + initials.pop().charAt(0);
            }
        } else {
            initials = name.substring(0, 2);
        }
        return initials.toUpperCase();
    };

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="fixed">
                <Toolbar>
                    <IconButton
                        // onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                        onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
            Car Cash
                    </Typography>

                    <Button color="inherit" onClick={(e) => handleClick(e)}>
                        {/* My Account */}
                        <Avatar src="/"
                            alt={getInitials(!!currentUser && currentUser.userExtraInfo.name)}
                        />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}>

                        <div
                            className={classes.userDropdown}>
                            <Avatar
                                src="/"
                                alt={getInitials(!!currentUser && currentUser.userExtraInfo.name)}
                                className={classes.largeAvatar} />
                            <Typography align="center" variant="h6">{!!currentUser && currentUser.userExtraInfo.name}</Typography>
                            <Typography align="center" variant="subtitle2">{!!currentUser && currentUser.userExtraInfo.email}</Typography>
                        </div>


                        {/* <MenuItem>
              <PersonOutlineOutlinedIcon fontSize="small" color="primary"/>&nbsp;&nbsp;
              My Account</MenuItem> */}
                        <MenuItem onClick={() => handleOpen()}>
                            <LockOutlinedIcon fontSize="small" color="primary" />&nbsp;&nbsp;
              Change Password</MenuItem>
                        <MenuItem onClick={() => logout()}>
                            {/* <ListItemIcon> */}
                            <ExitToAppIcon fontSize="small" color="secondary" /> &nbsp;&nbsp;
                            {/* </ListItemIcon> */}
              Sign out</MenuItem>
                    </Menu>


                    {/* <Button
            color="inherit"
            // className={classes.itemsList}
            onClick={() => logout()}
            // fullWidth
          >
            Logout
          </Button> */}
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                classes={{ paper: classes.drawer }}
                open={props.isDrawerOpen}
                variant="persistent">
                <List>
                    <ListItem
                        disableGutters
                        className={selectedMenu === 'dashboard' ? classes.activeMenu : classes.inactiveMenu}>
                        <Button
                            startIcon={<DashboardOutlinedIcon />}
                            onClick={() => {
                                sessionStorage.setItem('MENU_SELECTED', 'dashboard');
                                // setSelectedMenu("user");
                                history.push('/user/dashboard');
                            }}
                            fullWidth>
              Dashboard
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'application' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            startIcon={<AirplayOutlinedIcon />}
                            onClick={() => {
                                sessionStorage.setItem('MENU_SELECTED', 'application');
                                // setSelectedMenu("user");
                                history.push('/user/application');
                            }}
                            fullWidth>
              Applications
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'contract' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            startIcon={<ContactsOutlinedIcon />}
                            onClick={() => {
                                sessionStorage.setItem('MENU_SELECTED', 'contract');
                                // setSelectedMenu("user");
                                history.push('/user/contract');
                            }}
                            fullWidth>
              My Contracts
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'acivateLoan' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            startIcon={<ContactsOutlinedIcon />}
                            onClick={() => {
                                sessionStorage.setItem('MENU_SELECTED', 'acivateLoan');
                                // setSelectedMenu("user");
                                history.push('/user/activeloan');
                            }}
                            fullWidth>
              Active Loan
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'reportAccident dashboard' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            startIcon={<ReportProblemOutlinedIcon />}
                            onClick={() => {
                                sessionStorage.setItem('MENU_SELECTED', 'reportAccident');
                                // setSelectedMenu("user");
                                history.push('/user/reportAccident');
                            }}
                            fullWidth>
              Report an Accident
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'AddCard' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            startIcon={<PaymentOutlinedIcon />}
                            onClick={() => {
                                sessionStorage.setItem('MENU_SELECTED', 'AddCard');
                                // setSelectedMenu("user");
                                history.push('/user/AddCard');
                            }}
                            fullWidth>
              Card
                        </Button>
                    </ListItem>
                </List>
            </Drawer>
            <AppBar
                position="static"
                // color="primary"
                className={classes.footer}
                // style={{ position: "absolute !important", bottom: 0 }}
            >
                <Container maxWidth="lg">
                    <Toolbar>
                        <Typography
                            variant="body1"
                            color="inherit"
                            style={{ position: 'absolute', right: '0px' }}>
                            <Badge badgeContent={allRoomUnSeenMessageCount} color="secondary">
                                <SmsIcon
                                    style={{ cursor: 'pointer' }}
                                    fontSize="large"
                                    color="primary"
                                    onClick={handleChatOpen}
                                />
                            </Badge>
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
            <Dialog open={open}>
                <form className={classes1.root} onSubmit={handleSubmit}>
                    <TextField
                        label="New Password"
                        variant="filled"
                        type="password"
                        required
                        error={formData.newPasswordErr}
                        helperText={
                            formData.newPasswordErr ? formData.newPasswordErrMsg : ''
                        }
                        onChange={(e) => {
                            setFormData((prevState) => {
                                return {
                                    ...formData,
                                    newPasswordErr: false,
                                    newPassword: e.target.value,
                                };
                            });
                        }}
                    />
                    <TextField
                        label="Confirm Password"
                        variant="filled"
                        type="password"
                        required
                        error={formData.confirmPasswordErr}
                        helperText={
                            formData.confirmPasswordErr ? formData.confirmPasswordErrMsg : ''
                        }
                        onChange={(e) => {
                            setFormData((prevState) => {
                                return {
                                    ...formData,
                                    confirmPasswordErr: false,
                                    confirmPassword: e.target.value,
                                };
                            });
                        }}
                    />
                    <div>
                        <Button variant="contained" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </div>
                </form>
            </Dialog>
        </div>
    );
}
