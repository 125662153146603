import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SmsIcon from '@material-ui/icons/Sms';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { List, ListItem, Drawer, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useChat from 'hooks/useChat';
import useAuth from 'hooks/useAuth';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },

    drawer: {
        paddingTop: '5rem',
        width: '12rem',
    },
    drawerContainer: {
        overflow: 'auto',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemsList: {
        textAlign: 'left',
        display: 'block',
    },
    button: {
    //color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    btnRoot: {
        paddingLeft: '25px',
        justifyContent: 'left !important',
    },
    subMenu: {
        paddingLeft: '50px !important',
    },
    footer: {
        position: 'fixed !important',
        bottom: 0,
        backgroundColor: 'transparent',
        width: '118px',
        right: '0px',
        border: 'none',
        boxShadow: 'none',
    // color: theme.co
    },
}));

export default function CoBorrowerNav() {
    const classes = useStyles();
    const history = useHistory();
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const { logout } = useAuth();
    const { setShowChat, showChat, allRoomUnSeenMessageCount } = useChat();

    const handleChatOpen = React.useCallback(() => {
        setShowChat(true);
    }, [setShowChat]);

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="fixed">
                <Toolbar>
                    <IconButton
                        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Car Cash
                    </Typography>
                    <Button
                        color="inherit"
                        // className={classes.itemsList}
                        onClick={() => logout()}
                        // fullWidth
                    >
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                classes={{ paper: classes.drawer }}
                open={isDrawerOpen}
                variant="persistent">
                <List>
                    <ListItem
                        className={classes.item}
                        disableGutters
                        style={{ padding: '0px' }}>
                        <Button
                            className={classes.itemsList}
                            onClick={() => history.push('/co-borrower/dashboard')}
                            fullWidth>
              Contract
                        </Button>
                    </ListItem>
                </List>
            </Drawer>
            <AppBar
                position="static"
                // color="primary"
                className={classes.footer}
                // style={{ position: "absolute !important", bottom: 0 }}
            >
                <Container maxWidth="lg">
                    <Toolbar>
                        <Typography
                            variant="body1"
                            color="inherit"
                            style={{ position: 'absolute', right: '0px' }}>
                            <Badge badgeContent={allRoomUnSeenMessageCount} color="secondary">
                                <SmsIcon
                                    style={{ cursor: 'pointer' }}
                                    fontSize="large"
                                    color="primary"
                                    onClick={handleChatOpen}
                                />
                            </Badge>
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}
