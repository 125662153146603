import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;

  a {
    font-size: 1rem;
  }
`

class NotFound extends Component {
    render() {
        return <Container>
            <div>Page Not Found</div>
            <a href="/">Go to Main Page</a>
        </Container>;
    }
}

export default NotFound;
