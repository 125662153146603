import React from 'react';
import PropTypes from 'prop-types';

import GeneralNav from 'components/GeneralNav';

const GeneralLayout = ({ noLogin, ...props }) => {
    return (
        <React.Fragment>
            <GeneralNav noLogin={noLogin} />
            {props.children}
        </React.Fragment>
    );
};

GeneralLayout.propTypes = {
    noLogin: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

export default GeneralLayout;
