import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';

import AdminNav from 'components/AdminNav';
import Chat from 'components/chat/Index';
import useAuth from 'hooks/useAuth';
import useChat from 'hooks/useChat';

const useStyles = makeStyles((theme) => ({
    sidebarOpen : {
        paddingLeft :250,
        paddingTop : 64,
        [theme.breakpoints.down('sm')]: {
            paddingLeft :0,
        },
    },
    sidebarClose :{
        paddingLeft : 0,
        paddingTop : 64
    }
}));

const AdminLayout = (props) => {
    const classes = useStyles();
    const { currentUser } = useAuth();
    const { showChat } = useChat();
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    return (
        <React.Fragment>
            <AdminNav isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}/>

            <div className={isDrawerOpen? classes.sidebarOpen : classes.sidebarClose}>
                {props.children}
            </div>
            {showChat && <Chat />}
        </React.Fragment>
    );
};

AdminLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

export default AdminLayout;
