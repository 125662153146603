import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Container, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import useAuth from '../../hooks/useAuth';
import useChat from '../../hooks/useChat';
import RoomList from './RoomList';
import Message from './Message';
import UserMessage from './UserMessage';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    paper: {
    // boxShadow: "none",
        float: 'left',
        display: 'flex',
        width: '100%',
        marginBottom: '20px',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    chatContainer: {
        flexGrow: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Chat = () => {
    const { currentUser } = useAuth();

    const {
        showChat,
        setShowChat,
        setSelectedRoom,
        setAllRooms,
        setSelectedRoomAllMessages,
    } = useChat();
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChatOpen = React.useCallback(() => {
        setShowChat(false);
        setSelectedRoomAllMessages([]);
        setSelectedRoom(null);
        setAllRooms([]);
    }, [showChat]);

    return (
        <Container maxWidth="lg">
            <Box mt={8}>
                <Dialog
                    fullScreen
                    open={showChat}
                    onClose={handleClose}
                    disableEscapeKeyDown={true}
                    TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleChatOpen}
                                aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Messages
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.chatContainer}>
                        <Grid container spacing={0}>
                            {currentUser && currentUser.userExtraInfo.type === 'admin' && (
                                <RoomList />
                            )}
                            {currentUser && currentUser.userExtraInfo.type === 'admin' && (
                                <Grid item xs={6} sm={9}>
                                    <Message />
                                </Grid>
                            )}

                            {currentUser && (currentUser.userExtraInfo.type === 'coBorrower' || currentUser.userExtraInfo.type === 'user') && (
                                <Grid item xs={12} sm={12}>
                                    <UserMessage />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Dialog>
            </Box>
        </Container>
    );
};

export default Chat;
