import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

const AdminGuard = (props) => {
    const { currentUser } = useAuth();

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    if (currentUser.userExtraInfo.type === 'user') {
        return <Redirect to="/user/dashboard" />;
    }

    if (currentUser.userExtraInfo.type === 'coBorrower') {
        return <Redirect to="/co-borrower/dashboard" />;
    }

    return <React.Fragment>{props.children}</React.Fragment>;
};

AdminGuard.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

export default AdminGuard;
