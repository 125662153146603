import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import { app, db } from 'firebaseConf';
import useChat from 'hooks/useChat';
import useAuth from 'hooks/useAuth';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    paper: {
        boxShadow: 'none',
        height: 'calc(100vh - 64px)',
        float: 'left',
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    chatContainer: {
        flexGrow: 1,
    },
    roomList: {
        cursor: 'pointer',
    },
    selectedRoomList: {
    // background: theme.palette.secondary.main,
        background: 'rgba(0, 0, 0, 0.12)',
        color: '#0d0d0d !important',
    },
    chatContactList : {
        overflowX : 'hidden'
    }
}));

const RoomList = () => {
    // console.log(collection);
    const {
        showChat,
        allRooms,
        selectedRoom,
        setAllRooms,
        setSelectedRoom,
        setSelectedRoomAllMessages,
        setDocRef,
    } = useChat();
    const { currentUser } = useAuth();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    // const MSG_COUNT_REF = db.collection("messages");

    // const query = messagesRef.orderBy('createdAt').limit(25);

    //   const [roomLists, setRoomLists] = useState([]);

    const getUsers = async () => {
        app
            .firestore()
            .collection('user_details')
            .where('type', '!=', 'admin')
            .orderBy('type', 'asc')
            .orderBy('lastMessage', 'desc')
            .get()
            .then((snapshot) => {
                const rooms = snapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        name: doc.data().name,
                        email: doc.data().email,
                        loanId: doc.data().loanId,
                        phoneNumber: doc.data().phoneNumber,
                        type: doc.data().type,
                        uid: doc.data().uid,
                    };
                });
                console.log('rooms =', rooms);
                setAllRooms(rooms);
            });
    };

    const changeSelectedRoom = (room = {}) => {
    // console.log(room, "room");
        setSelectedRoom(room.uid);
    };

    const getRoomMessages = () => {
        const messagesRef = db
            .collection('messages')
            .where('room', '==', selectedRoom);
        setDocRef(messagesRef);
        messagesRef
            .orderBy('createdAt')
            .limit(40)
            .get()
            .then(function (documentSnapshots) {
                var lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];

                const list = [];
                documentSnapshots.forEach(function (doc) {
                    const { createdAt, photoURL, room, text, sender_id, receiver_view } =
            doc.data();
                    //pushing it to local array
                    list.push({
                        key: doc.id,
                        createdAt: createdAt,
                        photoURL,
                        room,
                        text,
                        sender_id,
                        receiver_view,
                    });
                });
                const value = orderBy(list, 'createdAt', 'asc');

                setSelectedRoomAllMessages(value);
            });
    };

    useEffect(() => {
        if (showChat) {
            getUsers();
        }
    }, [showChat]);

    useEffect(() => {
        console.log('selectedRoom', selectedRoom);
        if (selectedRoom) {
            // getRoomMessages();
        }
    }, [selectedRoom]);

    useEffect(() => {
        console.log('selectedRoom', selectedRoom);
        if (selectedRoom === null && allRooms.length > 0) {
            setSelectedRoom(allRooms[0].uid);
        }
    // setDocRef(messagesRef);
    }, [allRooms]);
    // console.log(allRooms);
    // useEffect(()=>{
    //   if(allRooms != null && allRooms.length>0 && selectedRoom){
    //     allRooms.map((rum, ind)=>{
    //       /* get unseen message count by room id*/

    //     })
    //   }
    // }, [allRooms, selectedRoom])

    useEffect(() => {
        if (allRooms.length > 0) {
            let masRef = db
                .collection('messages')
                .where('receiver_view', '==', false)
            const unsubscribe = masRef.onSnapshot((querySnapshot) => {
                // console.log("call unsub form room list");
                // console.log(querySnapshot, "querySnapshot");
                const unSeenMsg = querySnapshot.docs.map((doc) => {
                    return {
                        room: doc.data().room,
                        sender_id: doc.data().sender_id,
                    };
                });
                if (allRooms != null && allRooms.length > 0) {
                    allRooms.map((sRoom, i) => {
                        let c = 0;
                        unSeenMsg.map((t) => {
                            // console.log(t, "unseen msg", t.sender_id, currentUser.uid);

                            if (t.room === sRoom.uid && t.sender_id !== currentUser.uid) {
                                c = c + 1;
                            }
                        });
                        allRooms[i].unseen_count = c;
                    });
                    setAllRooms(allRooms);
                    // console.log(allRooms, "allRooms val");
                }
                // const value = _.orderBy(data, "createdAt", "asc");
                // setSelectedRoomAllMessages(value);

                // setSelectedRoomAllMessages(data);
            });
            return unsubscribe;
        }
    }, [allRooms]);

    return (
        <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>
                <List className={classes.chatContactList}
                    style={{
                        width: '100%',
                        height: '100%',
                        overflowY: 'auto',
                        boxShadow: '0px 2px 10px rgb(0, 0, 0, 0.25)',
                    }}>
                    {!loading &&
                        allRooms.length > 0 &&
                        allRooms.map((room, index) => (
                            <ListItem
                                key={index}
                                button
                                className={`${classes.roomList} ${
                                    selectedRoom === room.uid ? classes.selectedRoomList : ''
                                }`}
                                onClick={() => changeSelectedRoom(room)}
                            >
                                <ListItemText
                                    primary={`${room.name}`}
                                    secondary={`${room.email}`}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="start"
                                        aria-label="delete"
                                        style={{
                                            color: 'white',
                                            marginTop: '-22px',
                                            fontSize: '15px',
                                            // background: "#c4c4c4",
                                            background: `${room.unseen_count ? '#ff0000' : '#fff'}`,
                                            width: '20px',
                                            height: '13px',
                                        }}>
                                        {room.unseen_count ? room.unseen_count : 0}
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                    }
                </List>
            </Paper>
        </Grid>
    );
};

export default RoomList;
