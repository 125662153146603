import React, { Suspense, Fragment,lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from './components/Loading';

/* views file */
import NotFound from './components/NotFound';

import AdminLayout from './layout/AdminLayout';
import GeneralLayout from './layout/GeneralLayout';
import UserLayout from './layout/UserLayout';
import CoBorrowerLayout from './layout/CoBorrowerLayout';

import AdminGuard from './guards/AdminGuard';
import UserGuard from './guards/UserGuard';
import NonAuthGuard from './guards/NonAuthGuard';
import CoBorrowerGuard from './guards/CoBorrowerGuard';

const Home = React.lazy(() => import('./views/Home'));
const Login = React.lazy(() => import('./views/Login'));
const Dashboard = React.lazy(() => import('./views/admin/Dashboard'));
const CreateUser = React.lazy(() => import('./views/admin/CreateUser'));
const ViewUser = React.lazy(() => import('./views/admin/ViewUser'));
const ApplicationList = React.lazy(() =>
    import('./views/admin/ApplicationList')
);
const ApplicationEdit = React.lazy(() =>
    import('./views/admin/ApplicationEdit')
);
const Loan = React.lazy(() => import('./views/admin/Loan'));
const FinishSignUp = React.lazy(() => import('./views/FinishSignUp'));
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/ForgotPassword'));

/* user pages */
const UserHome = React.lazy(() => import('./views/user/Home'));
const Application = React.lazy(() => import('./views/user/Application'));
const ApplicationUpdate = React.lazy(() =>
    import('./views/user/ApplicationUpdate')
);
const UserApplicationCreate = lazy(()=>import('./views/user/ApplicationCreate'))
const LoanEdit = React.lazy(() => import('./views/admin/LoanEdit'));
const ContractList = React.lazy(() => import('./views/user/Contract'));
const ContractSignPayment = React.lazy(() =>
    import('./views/user/ContractSignPayment')
);
const AppSetting = React.lazy(() =>
    import('./views/admin/AppSetting')
);
const ContractVerification = React.lazy(() =>
    import('./views/admin/ContractVerification')
);
const ContractVerificationDetails = React.lazy(() =>
    import('./views/admin/ContractVerificationDetails')
);
const ReportAccident = React.lazy(() =>
    import('./views/user/ReportAccident')
);
const reportAccidentList = React.lazy(() =>
    import('./views/admin/ReportAccidentList')
);
const AddCard = React.lazy(() =>
    import('./views/user/AddCard')
);
const ActiveLoan = React.lazy(() =>
    import('./views/user/ActiveLoan')
);
const ActiveLoanadmin = React.lazy(() =>
    import('./views/admin/ActiveLoan')
);
const ClosedLoans = React.lazy(() => import('./views/admin/ClosedLoans'))
const ApplicationCreate = React.lazy(() =>
    import('./views/admin/ApplicationCreate')
);
const UserDetails = React.lazy(() =>
    import('./views/admin/UserDetails')
);
const ApplicationAdminUpdate = React.lazy(() =>
    import('./views/admin/ApplicationUpdate')
);
/* co-borrower pages */

// Added By Soumo

const Statistics = React.lazy(() =>
    import('./views/admin/Statistics')
);

const Fees = React.lazy(() =>
    import('./views/admin/Fees')
);

const BalanceReport = React.lazy(() =>
    import('./views/admin/BalanceReport')
);

let routes = [
    {
        Path: '/',
        Guard: null,
        Layout: GeneralLayout,
        Component: Home,
    },
    {
        Path: '/apply',
        Guard: NonAuthGuard,
        Layout: GeneralLayout,
        Component: UserApplicationCreate,
        noLogin: true,
    },
    {
        Path: '/login',
        Guard: NonAuthGuard,
        Layout: GeneralLayout,
        Component: Login,
    },
    {
        Path: '/finish-signup',
        Guard: null,
        Layout: GeneralLayout,
        Component: FinishSignUp,
    },

    {
        Path: '/forgot-password',
        Guard: NonAuthGuard,
        Layout: GeneralLayout,
        Component: ForgotPassword,
    },

    /* user routes */
    {
        Path: '/user/dashboard',
        Guard: UserGuard,
        Layout: UserLayout,
        Component: UserHome,
    },
    {
        Path: '/user/application',
        Guard: UserGuard,
        Layout: UserLayout,
        Component: Application,
    },
    {
        Path: '/user/application-update',
        Guard: UserGuard,
        Layout: UserLayout,
        Component: ApplicationUpdate,
    },
    {
        Path: '/user/application-create',
        Guard: UserGuard,
        Layout: UserLayout,
        Component: UserApplicationCreate,
    },
    {
        Path: '/user/contract',
        Guard: UserGuard,
        Layout: UserLayout,
        Component: ContractList,
    },
    {
        Path: '/user/contract-sign',
        Guard: UserGuard,
        Layout: UserLayout,
        Component: ContractSignPayment,
    },
    {
        Path: '/user/reportAccident',
        Guard: UserGuard,
        Layout: UserLayout,
        Component: ReportAccident,
    },
    {
        Path: '/user/addCard',
        Guard: UserGuard,
        Layout: UserLayout,
        Component: AddCard,
    },
    {
        Path: '/user/activeloan',
        Guard: UserGuard,
        Layout: UserLayout,
        Component: ActiveLoan,
    },
    {
        Path: '/co-borrower/dashboard',
        Guard: CoBorrowerGuard,
        Layout: CoBorrowerLayout,
        Component: ContractList,
    },
    {
        Path: '/co-borrower/contract-sign',
        Guard: CoBorrowerGuard,
        Layout: CoBorrowerLayout,
        Component: ContractSignPayment,
    },
    /* admin routes */
    {
        Path: '/admin/dashboard',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: Dashboard,
    },
    {
        Path: '/admin/activeloan',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: ActiveLoanadmin,
    },
    {
        Path: '/admin/closedloan',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: ClosedLoans,
    },
    {
        Path: '/admin/application-create',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: ApplicationCreate,
    },
    {
        Path: '/admin/create-user',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: CreateUser,
    },
    {
        Path: '/admin/reportAccident',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: reportAccidentList,
    },
    {
        Path: '/admin/application-update',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: ApplicationAdminUpdate,
    },
    // CreateUser

    {
        Path: '/admin/view-user',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: ViewUser,
    },
    // UserList
    {
        Path: '/admin/user_details',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: UserDetails,
    },

    // ViewUser
    {
        Path: '/admin/application-list',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: ApplicationList,
    },
    {
        Path: '/admin/application-edit',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: ApplicationEdit,
    },
    // Application
    {
        Path: '/admin/loan',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: Loan,
    },
    // Loan
    {
        Path: '/admin/loan-edit',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: LoanEdit,
    },
    // Loan
    {
        Path: '/admin/app-setting',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: AppSetting,
    },
    {
        Path: '/admin/contract-verification',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: ContractVerification,
    },
    {
        Path: '/admin/contract-verification-details',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: ContractVerificationDetails,
    },

    //Added by Soumo

    //Statistics
    {
        Path: '/admin/statistics',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: Statistics,
    },
    //Fees
    {
        Path: '/admin/fees',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: Fees,
    },
    {
        Path: '/admin/balence-report',
        Guard: AdminGuard,
        Layout: AdminLayout,
        Component: BalanceReport,
    },

    //Reset Password
    {
        Path: '/reset-password',
        Guard: NonAuthGuard,
        Layout: GeneralLayout,
        Component: ResetPassword,
    },
];

export const RouterConfig = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.Guard || Fragment;
                    const Layout = route.Layout || Fragment;
                    const Component = route.Component;
                    const noLogin = !!route.noLogin;

                    return (
                        <Route
                            key={i}
                            path={route.Path}
                            exact
                            render={(props) => (
                                <Guard>
                                    <Layout noLogin={noLogin}>
                                        <Component {...props} />
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </Suspense>
    );
};
