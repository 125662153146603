import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import firebase from '@firebase/app';
import moment from 'moment';

import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { db } from 'firebaseConf';
import useChat from 'hooks/useChat';
import useAuth from 'hooks/useAuth';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    paper: {
        boxShadow: 'none',
        float: 'left',
        // display: "flex",
        width: '100%',
        marginBottom: '20px',
        display: 'block',
        padding: '10px 15px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    chatContainer: {
        flexGrow: 1,
    },
    messageContainer: {
        height: 'calc(100vh - 180px)',
        'overflow-x': 'hidden',
        padding: '25px 20px 15px',
        background: '#FFFFFF',
        boxShadow: '0px 2px 15px rgb(0, 0, 0, 0.05)',
        marginBottom: '30px',
        borderRadius: '15px',
    },
    singleMsgInner: {
        marginTop: '8px',
        width: '100%',
        display: 'inline-block',
    },
    msgTxt: {
        whiteSpace: 'pre-line',
    },
    msgTime: {},
    msgWriteContainer: {
        border: 'none',
    },
    singleMsgInnerWrapper: {},
    loadMore: {
        textAlign: 'center',
        cursor: 'pointer',
    },
}));

const Message = () => {
    const {
        selectedRoom,
        setSelectedRoomAllMessages,
        messages,
    } = useChat();
    const dummy = React.useRef();
    const { currentUser } = useAuth();
    const classes = useStyles();
    const [formValue, setFormValue] = useState('');
    const [startPosition, setStartPosition] = useState();

    const sendMessage = async (e) => {
        e.preventDefault();

        await db.collection('messages').add({
            text: formValue,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            receiver_view: false,
            room: selectedRoom,
            photoURL: '',
            sender_id: currentUser.uid,
        });

        setFormValue('');
    };

    const fetchMoreData = () => {
        if (startPosition) {
            db.collection('messages')
                .where('room', '==', selectedRoom)
                .orderBy('createdAt', 'desc')
                .startAfter(startPosition)
                .limit(40)
                .get()
                .then(function (documentSnapshots) {
                    var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                    const list = [];
                    documentSnapshots.forEach(function (doc) {
                        const {
                            createdAt,
                            photoURL,
                            room,
                            text,
                            sender_id,
                            receiver_view,
                        } = doc.data();

                        list.push({
                            key: doc.id,
                            createdAt: createdAt,
                            photoURL,
                            room,
                            text,
                            sender_id,
                            receiver_view,
                        });
                    });
                    const value = orderBy(list, 'createdAt', 'asc');
                    setStartPosition(lastVisible);
                    let mMsg = value.concat(messages);
                    setSelectedRoomAllMessages(mMsg);
                });
        }
    };

    useEffect(() => {
        if (selectedRoom) {
            const unsubscribe = db
                .collection('messages')
                .where('room', '==', selectedRoom)
                .orderBy('createdAt', 'desc')
                .limit(40)
                .onSnapshot((querySnapshot) => {
                    var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    const data = querySnapshot.docs.map((doc) => {
                        if (
                            currentUser.uid !== doc.data().sender_id &&
              doc.data().receiver_view === false
                        ) {
                            console.log(currentUser.uid, doc.data().sender_id, 'console');
                            db.collection('messages')

                                .doc(doc.id)
                                .update({
                                    receiver_view: true,
                                })
                                .then(() => {
                                    console.log('Document updated');
                                })
                                .catch((error) => {
                                    console.error('Error updating doc', error);
                                });
                        }

                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    });
                    console.log(lastVisible, 'lastVisible');
                    setStartPosition(lastVisible);
                    const value = orderBy(data, 'createdAt', 'asc');
                    setSelectedRoomAllMessages(value);
                    dummy.current.scrollIntoView({ behavior: 'smooth' });
                });
            return unsubscribe;
        }
    }, [selectedRoom]);

    return (
        <Paper className={classes.paper}>
            <div className={classes.messageContainer}>
                {startPosition && messages.length >= 40 && (
                    <div className={classes.loadMore}>
                        <span onClick={fetchMoreData}>Load more</span>
                    </div>
                )}
                {messages &&
                    messages.length > 0 &&
                    messages.map((msg, ind) => (
                        <div key={ind} className={classes.singleMsgInnerWrapper}>
                            <div className={classes.singleMsgInner}>
                                <p
                                    className={
                                        msg.sender_id === currentUser.uid ? 'sender' : 'receiver'
                                    }
                                >
                                    <span>{msg.text}</span>
                                </p>

                                <span className={classes.msgTime}>
                                    { msg.createdAt ? moment(msg.createdAt.toDate()).format('HH/DD/yyyy - HH:MM') : '' }
                                </span>
                            </div>
                        </div>
                    ))
                }
                <span ref={dummy}></span>
            </div>
            <div className={classes.msgWriteContainer}>
                <textarea
                    value={formValue}
                    rows="1"
                    onChange={(e) => setFormValue(e.target.value)}
                    placeholder="Write here"
                    className="msg_field"></textarea>

                <Button
                    variant="contained"
                    color="primary"
                    disabled={!formValue}
                    onClick={sendMessage}
                    className="msg_send_btn">
                    Send
                </Button>
            </div>
        </Paper>
    );
};

export default Message;
