import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { List, ListItem, Drawer, Container, Avatar } from '@material-ui/core';
import useAuth from '../hooks/useAuth';
import useChat from '../hooks/useChat';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2';
import { app, auth } from '../firebaseConf';
import Badge from '@material-ui/core/Badge';
import SmsIcon from '@material-ui/icons/Sms';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import AirplayOutlinedIcon from '@material-ui/icons/AirplayOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import { AssignmentTurnedIn } from '@material-ui/icons'
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import TimelineIcon from '@material-ui/icons/Timeline';

import { setDecryptionKey, encrypt } from 'utils';

const KeyInputWrapper = styled.label`
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },

    drawer: {
        paddingTop: 70,
        width: 250,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemsList: {
        textAlign: 'left',
        display: 'block',
    },
    button: {
    //color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    btnRoot: {
        paddingLeft: '25px',
        justifyContent: 'left !important',
    },
    subMenu: {
        paddingLeft: '50px !important',
    },
    footer: {
        position: 'fixed !important',
        bottom: 0,
        backgroundColor: 'transparent',
        width: '118px',
        right: '0px',
        border: 'none',
        boxShadow: 'none',
    // color: theme.co
    },
    itemListShow: {
        background: '#3f51b5',
        color: '#FFFFFF',
    },
    activeMenu: {
        padding: '0 8px',
        '& button': {
            textTransform: 'capitalize',
            justifyContent: 'flex-start',
            minHeight: 48,
            backgroundColor: '#3f51b5',
            color: '#ffffff',
            padding: '10px 12px',
            '&:hover': {
                backgroundColor: '#3f51b5d9',
                color: '#ffffff',
            },
            '&.submenu': {
                marginLeft: 30,
            }
        }
    },
    inactiveMenu: {
        padding: '0 8px',
        '& button': {
            textTransform: 'capitalize',
            justifyContent: 'flex-start',
            minHeight: 48,
            padding: '10px 12px',
            '&.submenu': {
                marginLeft: 30,
            },
            '&:hover': {
                color: '#3f51b5',
            }

        }
    },
    largeAvatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        margin: 'auto'
    },
    userDropdown: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(3)
    }
}));
const useStylesForm = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '300px',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        },
    },

}));
const Navbar = (props) => {
    const classes = useStyles();
    const classes1 = useStylesForm();
    const history = useHistory();
    const { logout } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(
        sessionStorage.getItem('MENU_SELECTED')
            ? sessionStorage.getItem('MENU_SELECTED')
            : 'dashboard'
    );
    const { currentUser } = useAuth();
    const { setShowChat, allRoomUnSeenMessageCount } = useChat();

    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: '',
        confirmPasswordErr: false,
        confirmPasswordErrMsg: 'New Password and Password Confirmation should match',
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setKey = ({ target: { files } }) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            handleClose();
            if (event.target.result.length > 0 && event.target.result.indexOf('-----BEGIN RSA PRIVATE KEY-----') === 0) {
                setDecryptionKey(event.target.result);
                Swal.fire('', 'Key Is Set', 'success');
            } else {
                Swal.fire('', 'Invalid Key', 'error');
            }
        };

        reader.readAsText(files[0]);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    // function to handle modal close
    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.newPassword != formData.confirmPassword) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    confirmPasswordErr: true,
                };
            });
            return;
        }

        try {
            const user = auth.currentUser;
            user.updatePassword(formData.newPassword);
            Swal.fire('success', 'Password Changed Successfully', 'success');
            await logout();
            setOpen(false);
        } catch (error) {
            setOpen(true);
            Swal.fire('', error.message, 'info');
        }
    };

    const handleChatOpen = useCallback(() => {
        setShowChat(true);
    }, [setShowChat]);

    React.useEffect(() => {
        if (sessionStorage.getItem('MENU_SELECTED')) {
            setSelectedMenu(sessionStorage.getItem('MENU_SELECTED'));
        }
    }, [selectedMenu]);

    React.useEffect(() => {
        if (window.screen.width < 500) {
            props.setIsDrawerOpen(false);
        }
    }, []);

    const getInitials = (name) => {
        let initials = name.split(' ');
        if (initials.length > 1) {
            if (initials.length > 2) {
                initials =
          initials.shift().charAt(0) +
          '. ' +
          initials.shift().charAt(0) +
          '. ' +
          initials.pop().charAt(0);
            } else {
                initials = initials.shift().charAt(0) + '. ' + initials.pop().charAt(0);
            }
        } else {
            initials = name.substring(0, 2);
        }
        return initials.toUpperCase();
    };

    const handleMenuItemClick = (item, link) => () => {
        sessionStorage.setItem('MENU_SELECTED', item);
        history.push(link);
    };

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="fixed">
                <Toolbar>
                    <IconButton
                        onClick={() => props.setIsDrawerOpen(!props.isDrawerOpen)}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.title}>
                        <Button
                            color="inherit"
                            className={classes.title}
                            onClick={() => {
                                sessionStorage.setItem('MENU_SELECTED', 'dashboard');
                                // setSelectedMenu("user");
                                history.push('/admin/dashboard');
                            }}
                        >
                            Car Cash
                        </Button>
                    </Typography>
                    <Button color="inherit" onClick={(e) => handleClick(e)}>
                        {/* My Account */}
                        <Avatar src="/"
                            alt={getInitials(!!currentUser && currentUser.userExtraInfo.name)}
                        />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}>

                        <div
                            className={classes.userDropdown}>
                            <Avatar
                                src="/"
                                alt={getInitials(!!currentUser && currentUser.userExtraInfo.name)}
                                className={classes.largeAvatar} />
                            <Typography align="center" variant="h6">{!!currentUser && currentUser.userExtraInfo.name}</Typography>
                            <Typography align="center" variant="subtitle2">{!!currentUser && currentUser.userExtraInfo.email}</Typography>
                        </div>


                        {/* <MenuItem>
              <PersonOutlineOutlinedIcon fontSize="small" color="primary"/>&nbsp;&nbsp;
              My Account</MenuItem> */}
                        <MenuItem>
                            <KeyInputWrapper htmlFor="key-file">
                                <VpnKeyOutlinedIcon fontSize="small" color="primary" />&nbsp;&nbsp;Set Decryption Key
                            </KeyInputWrapper>
                            <input type="file" id="key-file" onChange={setKey} style={{ display: 'none' }} />
                        </MenuItem>
                        <MenuItem onClick={() => handleOpen()}>
                            <LockOutlinedIcon fontSize="small" color="primary" />&nbsp;&nbsp;Change Password
                        </MenuItem>
                        <MenuItem onClick={() => logout()}>
                            <ExitToAppIcon fontSize="small" color="secondary" /> &nbsp;&nbsp;Sign out
                        </MenuItem>
                    </Menu>
                    {/* <Button
          color="inherit"
            
               onClick={() => logout()}
            
              >
              Logout
            </Button> */}
                </Toolbar>
            </AppBar>
            <Dialog open={open}>
                <form className={classes1.root} onSubmit={handleSubmit}>
                    <TextField
                        label="New Password"
                        variant="filled"
                        type="password"
                        required
                        error={formData.newPasswordErr}
                        helperText={
                            formData.newPasswordErr ? formData.newPasswordErrMsg : ''
                        }
                        onChange={(e) => {
                            setFormData(() => {
                                return {
                                    ...formData,
                                    newPasswordErr: false,
                                    newPassword: e.target.value,
                                };
                            });
                        }}
                    />
                    <TextField
                        label="Confirm Password"
                        variant="filled"
                        type="password"
                        required
                        error={formData.confirmPasswordErr}
                        helperText={
                            formData.confirmPasswordErr ? formData.confirmPasswordErrMsg : ''
                        }
                        onChange={(e) => {
                            setFormData(() => {
                                return {
                                    ...formData,
                                    confirmPasswordErr: false,
                                    confirmPassword: e.target.value,
                                };
                            });
                        }}
                    />
                    <div>
                        <Button variant="contained" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </div>
                </form>
            </Dialog>
            <Drawer
                anchor="left"
                classes={{ paper: classes.drawer }}
                open={props.isDrawerOpen}
                variant="persistent">
                <List>
                    <ListItem
                        className={selectedMenu === 'dashboard' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            startIcon={<DashboardIcon />}
                            onClick={handleMenuItemClick('dashboard', '/admin/dashboard')}
                        >
                            Dashboard
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'user' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            startIcon={<PermIdentityOutlinedIcon />}
                            onClick={handleMenuItemClick('user', '/admin/view-user')}
                        >
                            Customers
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'application' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            startIcon={<AirplayOutlinedIcon />}
                            onClick={handleMenuItemClick('application', '/admin/application-list')}
                        >
                            Applications
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'loan' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            startIcon={<AccountBalanceWalletOutlinedIcon />}
                            onClick={handleMenuItemClick('loan', '/admin/loan')}
                        >
                            Loans
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'contract' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            className="submenu"
                            startIcon={<ContactMailOutlinedIcon />}
                            onClick={handleMenuItemClick('contract', '/admin/contract-verification')}
                        >
                            Contract Verification
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'acivateLoan' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            className="submenu"
                            startIcon={<AccountBalanceWalletOutlinedIcon />}
                            onClick={handleMenuItemClick('acivateLoan', '/admin/activeloan')}
                        >
                            Active Loans
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'closedLoan' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            className="submenu"
                            startIcon={<AssignmentTurnedIn />}
                            onClick={handleMenuItemClick('closedLoan', '/admin/closedloan')}
                        >
                            Closed Loans
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'statistics' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            startIcon={<EqualizerIcon />}
                            onClick={handleMenuItemClick('statistics', '/admin/statistics')}
                        >
                            Financial Statistics
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'balance_report' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            startIcon={<TimelineIcon />}
                            onClick={handleMenuItemClick('balance_report', '/admin/balance-report')}
                        >
                            Balance Report
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'setting' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            startIcon={<SettingsOutlinedIcon />}
                            onClick={handleMenuItemClick('setting', '/admin/app-setting')}
                        >
                            App Settings
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'fees' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            startIcon={<MonetizationOnOutlinedIcon />}
                            onClick={handleMenuItemClick('fees', '/admin/fees')}
                        >
                            Fees
                        </Button>
                    </ListItem>
                    <ListItem
                        className={selectedMenu === 'report_accident' ? classes.activeMenu : classes.inactiveMenu}
                        disableGutters>
                        <Button
                            fullWidth
                            startIcon={<ReportProblemOutlinedIcon />}
                            onClick={handleMenuItemClick('report_accident', '/admin/reportAccident')}
                        >
                            Accidents
                        </Button>
                    </ListItem>
                </List>
            </Drawer>

            <AppBar
                position="static"
                // color="primary"
                className={classes.footer}
                // style={{ position: "absolute !important", bottom: 0 }}
            >
                <Container maxWidth="lg">
                    <Toolbar>
                        <Typography
                            variant="body1"
                            color="inherit"
                            style={{ position: 'absolute', right: '0px' }}>
                            <Badge badgeContent={allRoomUnSeenMessageCount} color="secondary">
                                <SmsIcon
                                    style={{ cursor: 'pointer' }}
                                    fontSize="large"
                                    color="primary"
                                    onClick={handleChatOpen}
                                />
                            </Badge>
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
};
export default Navbar;
