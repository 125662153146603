import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import useAuth from 'hooks/useAuth';
import { db } from 'firebaseConf';

const initialChatState = {
    showChat: false /* based on this chat component will show hide */,
    allRooms: [] /* all room based on user */,
    selectedRoom: null /*if not select null if select room name*/,
    messages: [] /* all messages of selected room */,
    isUserOnline: false /* based on room select other user's state read */,
    unSeenMessageCount: 0 /* based on room messages read state count */,
    docRef: null /* messages collection reference */,
    allRoomUnSeenMessageCount: 0,
};

const ChatContext = createContext({
    ...initialChatState,
    setAllRooms: () => {},
    setSelectedRoom: () => {},
    setSelectedRoomAllMessages: () => {},
    setSelectedRoomUserStatus: () => {},
    setUnseenMessageCountOfSelectedRoom: () => {},
    setShowChat: () => {},
    setDocRef: () => {},
    setAllRoomUnSeenMessageCount: () => {},
});

const reducer = (state, action) => {
    const {
        showChat,
        allRooms,
        selectedRoom,
        messages,
        isUserOnline,
        unSeenMessageCount,
        docRef,
        allRoomUnSeenMessageCount,
    } = action.payload;
    switch (action.type) {
        case 'GET_SHOW_CHAT': {
            return {
                ...state,
                showChat,
            };
        }
        case 'GET_ALL_ROOM': {
            return {
                ...state,
                allRooms,
            };
        }
        case 'GET_SELECTED_ROOM': {
            return {
                ...state,
                selectedRoom,
            };
        }
        case 'GET_SELECTED_ROOM_ALL_MESSAGES': {
            return {
                ...state,
                messages,
            };
        }
        case 'GET_SELECTED_ROOM_USER_STATUS': {
            return {
                ...state,
                isUserOnline,
            };
        }
        case 'GET_UNSEEN_MESSAGE_COUNT_OF_SELECTED_ROOM': {
            return {
                ...state,
                unSeenMessageCount,
            };
        }
        case 'GET_SELECTED_ROOM_MSG_COL_REF': {
            return {
                ...state,
                docRef,
            };
        }
        case 'GET_ALL_ROOM_UNSEEN_MSG_COUNT': {
            return {
                ...state,
                allRoomUnSeenMessageCount,
            };
        }
        default: {
            return { ...state };
        }
    }
};

export const ChatProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const [state, dispatch] = useReducer(reducer, initialChatState);

    const setShowChat = (showChat = false) => {
        dispatch({
            type: 'GET_SHOW_CHAT',
            payload: {
                showChat: showChat,
            },
        });
    };

    const setAllRooms = (rooms = []) => {
        dispatch({
            type: 'GET_ALL_ROOM',
            payload: {
                allRooms: rooms,
            },
        });
    };

    const setDocRef = (ref = '') => {
        if (ref) {
            dispatch({
                type: 'GET_SELECTED_ROOM_MSG_COL_REF',
                payload: {
                    docRef: ref,
                },
            });
        }
    };

    const setSelectedRoom = (room = '') => {
        dispatch({
            type: 'GET_SELECTED_ROOM',
            payload: {
                selectedRoom: room,
            },
        });
    };

    const setSelectedRoomAllMessages = (messages = []) => {
        dispatch({
            type: 'GET_SELECTED_ROOM_ALL_MESSAGES',
            payload: {
                messages: messages,
            },
        });
    };

    const setSelectedRoomUserStatus = (isUserOnline) => {
        dispatch({
            type: 'GET_SELECTED_ROOM_USER_STATUS',
            payload: {
                isUserOnline: isUserOnline,
            },
        });
    };

    const setUnseenMessageCountOfSelectedRoom = (unSeenMessageCount = 0) => {
        dispatch({
            type: 'GET_UNSEEN_MESSAGE_COUNT_OF_SELECTED_ROOM',
            payload: {
                unSeenMessageCount: unSeenMessageCount,
            },
        });
    };

    const setAllRoomUnSeenMessageCount = (allRoomUnSeenMessageCount = 0) => {
        dispatch({
            type: 'GET_ALL_ROOM_UNSEEN_MSG_COUNT',
            payload: {
                allRoomUnSeenMessageCount: allRoomUnSeenMessageCount,
            },
        });
    };

    useEffect(() => {
        if (currentUser && currentUser.userExtraInfo.type === 'admin') {
            let masRef = db
                .collection('messages')
                .where('receiver_view', '==', false)
                .where('sender_id', '!=', currentUser.uid);
            const unsubscribe = masRef.onSnapshot((querySnapshot) => {
                const unSeenMsg = querySnapshot.docs.map((doc) => {
                    return {
                        room: doc.data().room,
                        sender_id: doc.data().sender_id,
                    };
                });
                setAllRoomUnSeenMessageCount(unSeenMsg.length);
            });
            return unsubscribe;
        }
        if (
            (currentUser && currentUser.userExtraInfo.type === 'user') ||
            (currentUser && currentUser.userExtraInfo.type === 'coBorrower')
        ) {
            let masRef = db
                .collection('messages')
                .where('receiver_view', '==', false)
                .where('room', '==', currentUser.uid)
                .where('sender_id', '!=', currentUser.uid);
            const unsubscribe = masRef.onSnapshot((querySnapshot) => {
                const unSeenMsg = querySnapshot.docs.map((doc) => {
                    return {
                        room: doc.data().room,
                        sender_id: doc.data().sender_id,
                    };
                });
                setAllRoomUnSeenMessageCount(unSeenMsg.length);
            });
            return unsubscribe;
        }
    }, [currentUser]);

    return (
        <ChatContext.Provider
            value={{
                ...state,
                setShowChat,
                setAllRooms,
                setSelectedRoom,
                setSelectedRoomAllMessages,
                setSelectedRoomUserStatus,
                setUnseenMessageCountOfSelectedRoom,
                setDocRef,
            }}>
            {children}
        </ChatContext.Provider>
    );
};

ChatProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

export default ChatContext;
